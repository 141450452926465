<template>
  <div>
    <vue-draggable-resizable
      :w="field.width"
      :h="'100px'"
      :draggable="false"
      @resizing="onResize"
      :parent="false"
      :resizable="true"
      class="resizable-component"
      :handles="['tr','mr','br']"
    >

      <component
        v-bind:is="field.components ? field.components.view : fieldMap[field.input_type]"
        :data="field"
      ></component>
    </vue-draggable-resizable>
  </div>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import { AllowedDivisionViewFields } from "../formComponentsView/AllowedDivisionViewFields";
export default {
  data(){
    return {
      fieldMap: {
          DATE: "DateView",
          FILE: "FileView",
          MULTI_LINE_TEXT: "MultiLineTextView",
          SINGLE_LINE_TEXT: "SingleLineTextView",
          MULTI_SELECT: "MultiSelectView",
          NUMBER: "NumberView",
          SELECT: "SelectView",
          LIST: "ListView",
          YES_OR_NO: "YesOrNoView",
          HEADING: "HeadingView",
          CHECKBOX: "CheckBoxView",
          SIGNATURE: "ESignatureView",
          GLOBAL_VAIRLABE: "GlobalVariableView",
          ENTITY_VARIABLE: "EntityVariableView",
          PARAGRAPH: "ParagraphView",
          SINGLE_LINE_CONTENT: "SingleLineContentView",
          AUTHORIZED_SIGNATURE: "AuthorizedSignatureView",
          IMAGE: "ImageView",
          FORMULA: "FormulaView",
          ACTION_BUTTON: "ActionButtonView",
          HTML: "HtmlView",
          ENTITY: "EntityView",
          CURRENCY: "CurrencyView",
          PHONE_COUNTRY_CODE: "PhoneCountryCodeView",
          DATE_TIME: "DateTimeView",
          DATE_TIME_RANGE: "DateTimeRangeView",
          DATE_RANGE: "DateRangeView",
          TIME: "TimeView",
          RADIO: "RadioView",
          WEEKDAYS: "WeekDaysView",
          TIME_RANGE: "TimeRangeView",
          CHECKBOX_GROUP: "CheckBoxGroupView",
          AGGREGATE_FUNCTION: "AggregateFunctionView",
          STAR_RATING: "StarRatingView",
          DATA_TABLE: "DataTableView",
          RADIO_BUTTON_GROUP: "RadioButtonGroupView",
          AUTO_INCREMENT_NUMBER: "AutoIncrementView",
          PAY_BUTTON: "PayButtonView",
          CONCATENATE: "ConcatenateView",
        },
    }
  },
  props: ["field", "x"],

  components: {
    VueDraggableResizable,
    ...AllowedDivisionViewFields
  },
  methods: {
    onResize(x, y, width, height) {
      this.field.width = width;
      this.field.height = height;
      this.field.x = x;
      this.field.y = y;

      this.$emit("resize", { height, width });
    }
  }
};
</script>

<style>
</style>